<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>求职信息</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="btnBox">
        <el-button type="primary" icon="el-icon-plus" @click="addJobInfo"
          >发布求职信息</el-button
        >
      </div>

      <div class="jobInfoBox">
        <div
          class="jobInfoItem"
          v-for="(item, index) in jobInfoList"
          :key="index"
        >
          <div class="jobInfoItemTop">
            <img
              class="photo"
              v-if="resume.avatar"
              :src="resume.avatar"
              alt=""
            />
            <img
              class="photo"
              v-else
              src="@/assets/home/people.png"
              alt=""
            />
            <div class="personInfo">
              <div class="personInfpLeft">
                <span class="f18">{{ item.name }}</span>
                <span class="f14 c666"
                  >{{ item.sex == 1 ? '男' : '女' }} | {{ item.age }}岁 |
                  {{ getJobUserType(item.title) }}</span
                >
                <div>
                <el-tag class="f14" size="small">{{
                  getExpectPostForId(tradeTypeList, item.expectPost)
                }}</el-tag>
                </div>
              </div>
              <div class="personInfpRight">
                <div class="location f14">
                  <i
                    class="iconfont icon-dingxiang"
                    style="margin-right: 15px"
                  ></i>
                  <span class="f14"
                    >{{ getProvinceForId(item.province) }}·{{
                      getCityForId(item.city)
                    }}</span
                  >
                </div>
                <div class="btn">
                  <span @click="editJobInformation(item.id)">去修改</span>
                  <!-- <span @click="delJobInformation(item.id)">删除</span> -->
                  <span @click="dialogDelJobInfo(item.id)">删除</span>
                </div>
              </div>
            </div>
          </div>
          <div class="jobInfoItemBottom c999">
            <span class="intro f14">{{ item.jobInfo }}</span>
            <span class="f12"
              >发布时间：{{
                item.createTime.substring(0, item.createTime.indexOf(' '))
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin-top: 20px">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
    </div>
    <el-dialog
  title="删除"
  :visible.sync="delDialog"
  width="30%">
  <span>是否删除该条求职信息？</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="delDialog = false">取 消</el-button>
    <el-button type="primary" @click="delJobInformation">确 定</el-button>
  </span>
</el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{ tips }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirm(confirmType)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { jobInfoPage, jobInfoDelete } from '@/api/jobInformation/jobInformation'
import { jobPostType } from '@/api/position/position'
import { detailResume } from '@/api/personalCenter/personalCenter'
import { oneEquityNum } from '@/api/equity/equity.js'

export default {
  name: '',
  data() {
    return {
      jobUserId: '',
      imgUrl: '',
      dict: [],
      jobUserType: [],
      total: 1,
      params: {
        pageNo: 1,
        pageSize: 10,
        jobUserId: localStorage.getItem('userId'),
      },
      tradeTypeList: [], //工种
      jobInfoList: [], //求职列表
      isResume: false, //是否有简历
      resume: {},
      showProvinceList: [],
      showCityList: [],
      jobVipEquity: [],
      dialogVisible: false,
      tips: '',
      jobVipEquityId: '',
      confirmType: '',
      delDialog: false,
      delJobInfoId: ''
    }
  },
  watch: {},
  props: {},
  components: {},
  computed: {},
  created() {
    this.jobUserId = localStorage.getItem('userId')
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.jobVipEquity = this.dict.filter(
      i => i.code == 'job_vip_equity'
    )[0].children
    this.jobUserType = this.dict.filter(
      i => i.code == 'job_user_type'
    )[0].children
    this.showProvinceList = JSON.parse(localStorage.getItem('provincialList'))
    this.showCityList = JSON.parse(localStorage.getItem('cityList'))
    this.getDetailResume()
    this.getJobTradeType()
    this.getJobInfoPage()
  },
  mounted() {},
  methods: {
    getJobUserType(code) {
      return this.jobUserType.filter(i => i.code == code)[0].name
    },
    // 回显省份
    getProvinceForId(id) {
      return this.showProvinceList.filter(i => i.PROVINCE_CODE == id)[0]
        .SHORT_NAME
    },
    // 回显城市
    getCityForId(id) {
      return this.showCityList.filter(i => i.CITY_CODE == id)[0].SHORT_NAME
    },
    getJobInfoPage() {
      jobInfoPage(this.params).then(res => {
        this.jobInfoList = res.data.rows
        this.total = res.data.totalRows
      })
    },
    chengePagination(cur) {
      this.params.pageNo = cur
      this.getJobInfoPage()
    },
    // 回显工种
    getExpectPostForId(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.getExpectPostForId(list[i].children, id)
          if (title) return title
        }
      }
      return title
    },
    dialogDelJobInfo(id) {
      this.delDialog = true
      this.delJobInfoId = id
    },
    // 删除求职信息
    // delJobInformation(id) {
    delJobInformation() {
      // jobInfoDelete([{ id: id }]).then(res => {
      jobInfoDelete([{ id: this.delJobInfoId }]).then(res => {
        console.log(res, '删除')
        if (res.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.delDialog = false
          this.getJobInfoPage()
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    // 编辑求职信息
    editJobInformation(id) {
      this.$router.push({ query: { id: id }, path: 'addMyJobInformation' })
    },
    // 工种
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.tradeTypeList = res.data[0].children
      })
    },
    // 添加求职信息时查询是否有简历
    getDetailResume() {
      detailResume({ JobUserId: localStorage.getItem('userId') }).then(res => {
        if (res.data) {
          this.resume = res.data
          this.isResume = true
        } else {
          this.isResume = false
        }
      })
    },
    addJobInfo() {
      this.$router.push('addMyJobInformation')
      // if (this.isResume) {
      //   // this.$router.push('addMyJobInformation')
      //   // this.dialogVisible = true
      //   this.jobVipEquityId = this.jobVipEquity.filter(i => i.code == 4)[0].name
      //   // 获取权益次数
      //   // this.getEquityNum(this.jobUserId,this.jobVipEquityId)
      //   this.getEquityNum(this.jobUserId,4)
      // } else {
      //   this.$message.warning('请先添加简历')
      // }
    },
    // 确定对话框
    confirm(confirmType) {
      console.log(confirmType,'confirmType');
      // 1:前往充值 2:花权益购买
      if(confirmType == 1) {
        this.$router.push('/personalCenter/memberCenter')
      } else if (confirmType == 2) {
        this.$router.push('addMyJobInformation')
        // this._subtractEquityNum()
      } else if(confirmType == '') {
        this.dialogVisible = false
      }
    },
    // 取消对话框
    dialogCancel() {
      this.dialogVisible = false
      this.confirmType = ''
    },
    // 获取权益次数
    getEquityNum(jobUserId,jobVipEquityId) {
      let obj = {
        jobUserId: jobUserId,
        // jobVipEquityId: jobVipEquityId,
        index: jobVipEquityId,
        userType: 1,
      }
      oneEquityNum(obj).then(res => {
        console.log(res,'权益次数');
        if(res.success) {
          this.dialogVisible = true
          if(res.data == 0) {
            this.tips = '当前权益次数不足，是否前往充值？'
            this.confirmType = 1
          } else {
            this.tips = '发布求职信息将扣除您的相关权益，是否发布？'
            this.confirmType = 2
          }
        }
      })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.f18 {
  font-size: 18px;
}
.f14 {
  font-size: 14px;
}
.f12 {
  font-size: 12px;
}
.c666 {
  color: #666;
}
.c999 {
  color: #999;
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.btnBox {
  margin: 0 48px;
  background-color: #fafafa;
  display: flex;
  justify-content: flex-end;
}

.jobInfoBox {
  padding: 0 48px;
  margin-top: 15px;
  padding-bottom: 70px;
  .jobInfoItem {
    padding: 15px 0 12px 0;
    border-bottom: 1px solid #ccc;
    .jobInfoItemTop {
      display: flex;
      margin-bottom: 10px;
      .photo {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 14px;
      }
      .personInfo {
        flex: 1;
        display: flex;
        justify-content: space-between;
        .personInfpLeft {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .personInfpRight {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .location {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #1592ea;
          }
          .btn {
            display: flex;
            span {
              width: 80px;
              height: 24px;
              text-align: center;
              line-height: 24px;
              font-size: 12px;
              margin-left: 20px;
              cursor: pointer;
            }
            span:nth-child(1) {
              border: 1px solid #029aff;
              border-radius: 3px;
              color: #029aff;
            }
            span:nth-child(2) {
              border: 1px solid #eb1e1e;
              border-radius: 3px;
              color: #eb1e1e;
            }
          }
        }
      }
    }
    .jobInfoItemBottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 46px;
      background-color: #fafafa;
      padding: 0 20px;
      .intro {
        width: 76%;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
